const advantages = [
  {
    name: "Lobby",
    img: require("@i/html/home/feats-1.jpg"),
    imgsm: require("@i/html/home/feats-1-750.jpg"),
    title: "Лобби от дизайн-студии Asthetique",
    text: "В&nbsp;эксклюзивных дизайнерских лобби природные материалы сочетаются с&nbsp;инновационными и&nbsp;экологичными материалами нового поколения.",
  },
  {
    name: "Sport",
    img: require("@i/html/home/feats-2.jpg"),
    imgsm: require("@i/html/home/feats-2-750.jpg"),
    title: "Фитнес-центр, SPA, спортивные площадки",
    text: "VERY станет вашим лучшим местом для занятий спортом&nbsp;&mdash; от&nbsp;зарядки в&nbsp;суперсовременном фитнес-зале до&nbsp;йоги в&nbsp;тиши векового леса.",
    link: "Sport",
  },
  {
    name: "Entertainment",
    img: require("@i/html/home/feats-3.jpg"),
    title: "Общественные пространства",
    text: "VERY&nbsp;&mdash; это целый мир ваших любимых удовольствий. Комфортный, продуманный и&nbsp;красивый, он&nbsp;будет радовать вас каждый день.",
    link: "Entertainment",
  },
  {
    name: "Kids",
    img: require("@i/html/home/feats-4.jpg"),
    title: "Инфраструктура для&nbsp;детей",
    text: "Развивающие игры, первые победы, новые знакомства и&nbsp;крепкая дружба. Все это ждет ваших детей во&nbsp;дворе уникальных впечатлений.",
    link: "Kids",
  },
  {
    name: "Parking",
    img: require("@i/html/home/feats-5.jpg"),
    title: "Паркинг и&nbsp;кладовые",
    text: "В&nbsp;доме хватит места для всего, что вам дорого. Спускайтесь к&nbsp;автомобилю на&nbsp;лифте прямо от&nbsp;квартиры, освобождайте пространство от&nbsp;больших и&nbsp;сезонных вещей.",
    link: "Parking",
  },
  {
    name: "Smart",
    img: require("@i/html/home/feats-6.jpg"),
    title: "Умный дом",
    text: "Мгновенно реагирует на&nbsp;ваши желания, знает вас в&nbsp;лицо, обеспечивает безопасность и&nbsp;делает жизнь комфортнее в&nbsp;каждой детали.",
    link: "Smart",
  },
  {
    name: "Coworking",
    img: require("@i/html/home/feats-7.jpg"),
    title: "Коворкинг",
    text: "Работайте и&nbsp;проводите деловые встречи с&nbsp;комфортом, пока дети в&nbsp;саду или играют во&nbsp;дворе. Зона коворкинга может легко трансформироваться в&nbsp;мини-лекторий для презентаций и&nbsp;совещаний.",
  },
];

export default advantages;
