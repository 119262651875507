<template>
  <div class="header-dropdown" :class="{ active: isActive }">
    <div class="container">
      <div class="header-dropdown-grid">
        <div class="header-dropdown-fav d-xl-none">
          <div
            v-if="favouritesStore.state.favourites.length > 0"
            class="header-favourite"
          >
            <router-link
              class="header-favourite__icon"
              :to="{ name: 'PlanFavourites' }"
              aria-label="Избранные квартиры"
            >
              <Icon icon="heart" />
              <span class="header-favourite__label">{{
                favouritesStore.state.favourites.length
              }}</span>
              <span class="header-favourite__text">Избранное</span>
            </router-link>
          </div>
        </div>
        <div class="header-dropdown-nav h3">
          <ul ref="dropdownNav1">
            <li>
              <router-link :to="{ name: 'PlanArchive' }">
                Выбор квартиры
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PlanUnicLotsSearch' }">
                Уникальные лоты
              </router-link>
            </li>
            <li>
              <router-link to="/#service-apart">
                Сервисное управление
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Entertainment' }">
                Развлечения
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Kids' }"> Детям </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Sport' }"> Спорт & SPA </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Termoland' }"> Termoland </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Advantages' }">
                Преимущества
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Gallery' }"> Галерея </router-link>
            </li>
          </ul>
        </div>
        <div class="header-dropdown-sub">
          <ul ref="dropdownNav2">
            <li>
              <router-link :to="{ name: 'Purchase' }">
                Варианты покупки
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Online' }">
                Покупка онлайн
              </router-link>
            </li>
            <li>
              <a href="https://trade-estate.ru/rent/very/" target="_blank">
                Коммерческая недвижимость
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'News' }"> Новости </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Construction' }">
                Ход строительства
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Smart' }"> Умный дом </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Parking' }">
                Паркинг и&nbsp;кладовые
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Developer' }">
                О девелопере
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Documents' }"> Документы </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Contacts' }"> Контакты </router-link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                data-popup-with-chats
                data-popup-with-chats-title="Получить буклет"
                data-popup-with-chats-source-id="Получить буклет"
                >Получить буклет</a
              >
            </li>
          </ul>
        </div>
        <div class="header-dropdown-cosial">
          <ul v-if="$social" ref="dropdownNav3">
            <li v-for="(value, name) in $social" :key="name">
              <a
                :href="value.url"
                :aria-label="'Мы в ' + value.name"
                target="_blank"
                rel="noreferrer"
                >{{ value.name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="header-dropdown-contacts">
          <ul ref="dropdownNav4">
            <li v-if="phone">
              <div class="header-dropdown-contacts__title">
                <a
                  :href="'tel:' + $filters.phoneFormat(phone)"
                  class="comagic_phone"
                >
                  {{ phone }}
                </a>
              </div>
              <div class="header-dropdown-contacts__text">Офис продаж</div>
            </li>
            <!-- <li>
              <div class="header-dropdown-contacts__title">
                <a :href="'mailto:' + $email">{{ $email }}</a>
              </div>
              <div class="header-dropdown-contacts__text">Офис продаж</div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import _forEach from "lodash/forEach";
import { is_touch } from "@/helpers.js";
export default {
  inject: ["favouritesStore", "mainStore"],
  props: {
    isActive: {
      type: Boolean,
    },
  },
  computed: {
    phone() {
      return this.mainStore.state.data?.addresses.addr_list[0].addr_phone;
    },
  },
  watch: {
    isActive(status) {
      if (!is_touch()) {
        if (status) {
          const tlShow = gsap.timeline();
          _forEach(this.$refs, (element) => {
            tlShow.add(
              gsap.fromTo(
                element.children,
                {
                  y: 20,
                  autoAlpha: 0,
                },
                {
                  y: 0,
                  autoAlpha: 1,
                  stagger: { each: 0.07 },
                  duration: 0.3,
                }
              ),
              0
            );
          });
        } else {
          const tlHide = gsap.timeline();
          _forEach(this.$refs, (element) => {
            tlHide.add(
              gsap.fromTo(
                element.children,
                {
                  y: 0,
                  autoAlpha: 1,
                },
                {
                  y: 20,
                  autoAlpha: 0,
                  stagger: { each: 0.07 },
                  duration: 0.3,
                }
              ),
              0
            );
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.header-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: calc(100vh - 76px);
  margin-top: 1px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.header-dropdown.active {
  opacity: 1;
  visibility: visible;
}

.header-dropdown-grid {
  display: grid;
  grid-template-areas:
    "nav sub cosial"
    "nav contacts contacts";
  grid-template-rows: 1fr auto;
  grid-template-columns: 2fr 1.21fr 0.79fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  gap: 0 0;
  width: 100%;
  height: 100%;
}

.header-dropdown-nav {
  grid-area: nav;
  margin: 0;
  padding: 70px 40px 70px 0;
  border-right: 1px solid #e5e5e5;
}

.header-dropdown-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-dropdown-nav ul li {
  margin: 0 0 32px;
  padding: 0;
}

.header-dropdown-nav ul li:last-child {
  margin-bottom: 0;
}

.header-dropdown-nav ul li a {
  color: inherit;
  text-decoration: none;
}

.header-dropdown-nav ul li a.router-link-active {
  background-color: #205640;
  background-image: url(~@i/text-mask.png);
  background-position: 50% 50%;
  background-clip: text;
  background-clip: text;
  background-size: 420px 560px;
  -webkit-text-fill-color: transparent;
}

body.-notouch .header-dropdown-nav ul li a:hover {
  color: #205640;
}

.header-dropdown-sub {
  grid-area: sub;
  padding: 75px 40px 75px 50px;
  border-right: 1px solid #e5e5e5;
  font-size: 24px;
}

.header-dropdown-sub ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-dropdown-sub ul li {
  margin: 0 0 9px;
  padding: 0;
}

.header-dropdown-sub ul li:last-child {
  margin-bottom: 0;
}

.header-dropdown-sub ul li a {
  color: inherit;
  text-decoration: none;
}

.header-dropdown-sub ul li a.router-link-active {
  color: #509c3b;
}

body.-notouch .header-dropdown-sub ul li a:hover {
  color: #205640;
  text-decoration: underline;
}

.header-dropdown-cosial {
  grid-area: cosial;
  padding: 75px 40px 75px 50px;
  font-size: 24px;
}

.header-dropdown-cosial ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-dropdown-cosial ul li {
  margin: 0 0 9px;
  padding: 0;
}

.header-dropdown-cosial ul li:last-child {
  margin-bottom: 0;
}

.header-dropdown-cosial ul li a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .header-dropdown-cosial ul li a:hover {
  color: #205640;
  text-decoration: underline;
}

.header-dropdown-contacts {
  grid-area: contacts;
  min-height: 200px;
  padding: 50px 0 20px 50px;
  border-top: 1px solid #e5e5e5;
}

.header-dropdown-contacts ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-dropdown-contacts ul li {
  margin: 0 100px 30px 0;
  padding: 0;
}

.header-dropdown-contacts ul li:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.header-dropdown-contacts ul li a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .header-dropdown-contacts ul li a:hover {
  color: #205640;
}

.header-dropdown-contacts__title {
  font-weight: normal;
  font-size: 24px;
}

.header-dropdown-contacts__text {
  font-size: 16px;
}

.header-favourite {
  position: relative;
  display: flex;
  align-items: center;
  height: 72px;
}

.header-favourite__text {
  position: absolute;
  top: 3px;
  left: 58px;
  color: #3f4440;
  font-weight: normal;
  font-size: 20px;
}

.router-link-active .header-favourite__text,
body.-notouch a:hover .header-favourite__text {
  color: #205640;
}

@media (max-width: 1279px) {
  .header-dropdown-grid {
    display: grid;
    grid-template-areas:
      "nav fav"
      "nav sub"
      "contacts cosial";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1.1fr 0.9fr;
    grid-auto-columns: 1fr;
    gap: 0 0;
  }

  .header-dropdown-nav {
    padding: 32px 32px 32px 0;
  }

  .header-dropdown-nav ul li {
    margin-bottom: 27px;
  }

  .header-dropdown-fav {
    padding: 0 32px;
    border-bottom: 1px solid #e5e5e5;
  }

  .header-dropdown-sub {
    padding: 32px;
    border-right: none;
  }

  .header-dropdown-contacts {
    min-height: auto;
    padding: 32px 32px 32px 0;
    border-right: 1px solid #e5e5e5;
  }

  .header-dropdown-contacts ul li {
    margin: 0 40px 0 0;
  }

  .header-dropdown-contacts ul li:last-child {
    margin-right: 0;
  }

  .header-dropdown-cosial {
    padding: 32px;
    border-top: 1px solid #e5e5e5;
  }
}
@media (max-width: 991px) {
  .header-dropdown-nav ul li {
    margin-bottom: 22px;
  }

  .header-dropdown-sub {
    font-size: 20px;
  }

  .header-dropdown-sub ul li {
    margin-bottom: 6px;
  }

  .header-dropdown-cosial {
    font-size: 20px;
  }

  .header-dropdown-cosial ul li {
    margin-bottom: 6px;
  }

  .header-dropdown-contacts ul {
    display: block;
  }

  .header-dropdown-contacts ul li {
    margin: 0 0 25px;
  }

  .header-dropdown-contacts ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .header-dropdown-grid {
    display: block;
  }

  .header-dropdown-fav {
    padding: 0;
  }

  .header-dropdown-nav {
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid #e5e5e5;
    font-size: 45px;
  }

  .header-dropdown-nav ul li {
    margin-bottom: 14px;
  }

  .header-dropdown-sub {
    padding: 28px 0;
  }

  .header-dropdown-cosial {
    padding: 28px 0;
  }

  .header-dropdown-contacts {
    padding: 28px 0;
    border-right: 0;
  }
}
</style>
