<template>
  <section class="unic-lots home-block">
    <div class="content">
      <div class="container">
        <div ref="title" class="h2 title">Уникальные лоты</div>
        <SlidesScroll v-if="mq.lgPlus">
          <FlipFlat v-for="item in UnicLots" :key="item.title" :item="item" />
        </SlidesScroll>
        <div v-else class="list">
          <div class="unic-lots__mob-control">
            <button class="unic-lots__button-left unic-lots__button"><img src="@/assets/i/cursor-arrow.png"></button>
            <button class="unic-lots__button-right unic-lots__button"><img src="@/assets/i/cursor-arrow.png"></button>
          </div>
          <swiper
            class="hasPagination"
            :pagination="{ clickable: true }"
            :preload-images="false"
            :slides-per-view="'auto'"
            :space-between="24"
            :navigation="{
              nextEl: '.unic-lots__button-right',
              prevEl: '.unic-lots__button-left'
            }"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="(item, i) in UnicLots" :key="i" class="unic-lots__mob-item">
              <FlipFlat :item="item" />
            </swiper-slide>
          </swiper>
        </div>
        <div ref="bottom" class="btns-list d-md-flex justify-content-center">
          <div class="btns-list__item">
            <router-link :to="{ name: 'PlanUnicLotsSearch' }" class="btn btn-default">
              Выбрать квартиру
            </router-link>
          </div>
          <div class="btns-list__item">
            <button class="btn btn-transparent" data-popup-with-chats data-popup-with-chats-title="Связаться со&nbsp;специалистом">
              Связаться со&nbsp;специалистом
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SlidesScroll from "@/components/SlidesScroll.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import FlipFlat from "@/components/FlipFlat.vue";
import UnicLots from '@/content/unicLots.js'
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    SlidesScroll,
    GalleryItem,
    Swiper,
    SwiperSlide,
    FlipFlat,
  },
  inject: ["mq"],
  data() {
    return {
      UnicLots: UnicLots
    };
  },
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top bottom",
            end: "10% center",
            scrub: 1.5,
          },
        })
        .from(this.$refs.title, {
          y: 150,
        });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.bottom,
            toggleActions: "play reverse play reverse",
          },
        })
        .from(this.$refs.bottom, {
          y: 50,
          autoAlpha: 0,
        });
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style scoped>
.content {
  position: relative;
}

.title {
  margin-bottom: 80px;
  text-align: center;
}
.list {
  position: relative;
}
.list .swiper-container {
  overflow: inherit;
}

.list :deep(.swiper-slide) {
  width: 392px;
}

.btns-list {
  margin-top: 80px;
}
.unic-lots__desk-item {
  width: 420px;
}
.unic-lots__mob-control {
  position: absolute;
  width: 100%;
  height: 100%;
}
.unic-lots__button {
  position: absolute;
  z-index: 2;
  width: 44px;
  height: 44px;
  border: 0;
  background: transparent;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}
.unic-lots__button-left {
  left: -9px;
}
.unic-lots__button-left img {
  transform: rotate(180deg);
}
.unic-lots__button-right {
  left: 291px;
}
@media (max-width: 1279px) {
  .title {
    margin-bottom: 65px;
  }
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 40px;
  }

  .list :deep(.item__image) {
    height: 450px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 187px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 32px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 203px;
  }

  .btns-list {
    margin-top: 110px;
    text-align: center;
  }

  .btns-list__item {
    margin-bottom: 10px;
  }

  .btns-list__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }

  .list :deep(.item__text) {
    font-size: 14px;
    line-height: 1.4;
  }

  .list :deep(.item__image) {
    height: 400px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    top: 178px;
  }
}
@media (max-width: 374px) {
  .list :deep(.swiper-slide) {
    width: 290px;
  }

  .btns-list__item .btn-transparent {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
