<template>
  <section class="home-block py-md-0">
    <div class="title__container">
      <div class="container">
        <div ref="title" class="title h1">
          VERY<br />
          атмосферно
        </div>
      </div>
    </div>

    <div class="video text-white">
      <div class="background">
        <div ref="background" class="video-background">
          <img
            loading="lazy"
            width="1903"
            height="780"
            src="@i/video/home-capital.jpg"
            alt="VERY атмосферно"
          />
        </div>
      </div>
      <div ref="particle" class="particle" />
      <div class="play-btn__wrap">
        <a
          ref="play"
          href="javascript:void(0);"
          class="play-btn cursor-zoom"
          aria-label="Открыть видео"
          @click="
            $vfm.show({
              component: 'PopupVideo',
              bind: { youtube: 'vblz3TzdYt8' },
            })
          "
        >
          <span ref="playWords" class="play-btn__words" />
          <span class="play-btn__icon"><Icon icon="play" /></span>
        </a>
      </div>
    </div>
    <div class="text__container">
      <div class="container">
        <div ref="text" class="text">
          <p>
            VERY меняет всё. Это как нажать на&nbsp;Play в&nbsp;самой жизни.
            Мы&nbsp;хотим передать вам это удивительное ощущение.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      const rotationTL = gsap.to(this.$refs.playWords, {
        rotation: "360",
        duration: 30,
        ease: "none",
        repeat: -1,
      });
      this.$refs.play.addEventListener("mouseenter", () => rotationTL.pause());
      this.$refs.play.addEventListener("mouseleave", () => rotationTL.resume());

      gsap.fromTo(
        this.$refs.background,
        { y: "-25%" },
        {
          y: "25%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
          ease: "none",
        }
      );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 60%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([
            this.$refs.title,
            this.$refs.play,
            this.$refs.text,
          ]),
          {
            y: 250,
            stagger: { each: 0.1 },
          },
          0
        )
        .from(
          this.$refs.particle,
          {
            autoAlpha: 0,
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.video {
  position: relative;
  overflow: hidden;
  height: 760px;
}

body.-notouch .video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63 68 64 / 0.2);
}

.particle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 957px;
  height: 818px;
  margin-top: -409px;
  margin-left: -210px;
  background: url(~@i/bg-family.svg) 0 0 no-repeat;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 74px;
  height: 74px;
  margin: -37px 0 0 -37px;
}

.play-btn__icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  padding-left: 5px;
  border: 5px solid #fff;
  border-radius: 50%;
  background: linear-gradient(180deg, #509c3b 0%, #95c389 100%);
}

body.-notouch .play-btn:hover .play-btn__icon {
  background: linear-gradient(180deg, #62a650 0%, #a0c994 100%);
}

body.-notouch .play-btn:active .play-btn__icon {
  background: linear-gradient(180deg, #498c36 0%, #86af7a 100%);
}

.play-btn__words {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 145px;
  height: 145px;
  margin: -72px 0 0 -72px;
  background: url(~@i/play-btn-words-2.png) 0 0 no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  .title__container {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 2;
    width: 100%;
    color: #fff;
  }

  .text__container {
    position: absolute;
    bottom: 55px;
    left: 0;
    z-index: 2;
    width: 100%;
    color: #fff;
  }
}

.text {
  max-width: 350px;
}
@media (max-width: 1279px) {
  .video {
    height: 685px;
  }

  .play-btn {
    margin-top: 0;
  }

  .particle {
    transform: scale(0.9);
  }
}
@media (max-width: 991px) {
  .video {
    height: 610px;
  }

  .particle {
    transform: scale(0.8);
  }
}
@media (max-width: 767px) {
  .video {
    height: 490px;
  }

  .title {
    text-align: center;
  }

  .text {
    max-width: 310px;
    margin: 30px auto 0;
    text-align: center;
  }

  .play-btn {
    margin-top: -37px;
  }

  .home-block {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .video {
    height: auto;
    padding-bottom: 85%;
  }

  .particle {
    top: 0;
    left: 25%;
    width: 100%;
    height: 100%;
    margin: 0;
    background-position: 50% 96%;
    background-size: auto 100%;
    transform: scale(1);
  }
}
@media (max-width: 374px) {
}
</style>
