<template>
  <div ref="container" class="horizontal__container">
    <div ref="pin" class="horizontal__pin">
      <div ref="wrap" class="horizontal__wrap d-md-flex">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  mounted() {
    const pin = this.$refs.pin;
    const wrap = this.$refs.wrap;
    gsap.matchMedia().add("(min-width: 768px)", () => {
      gsap.to(wrap, {
        x: () => -(wrap.scrollWidth - pin.clientWidth),
        ease: "none",
        snap: "x",
        scrollTrigger: {
          trigger: pin,
          start: "center center",
          end: () => "+=" + wrap.scrollWidth / 2,
          pin: pin,
          invalidateOnRefresh: true,
          scrub: 0.5,
          //markers: true,
          //fastScrollEnd: fastScrollEnd,
          //preventOverlaps: preventOverlaps,
          // onUpdate: (e) => {
          // 	console.log(e.progress);
          // },
        },
      });
    });

    if (!is_touch()) {
      const itemHeight =
        this.$refs.container.querySelector(".item").clientHeight / 2;
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.container,
            //markers: true,
            start: "top bottom",
            end: "bottom-=" + itemHeight + "px center",
            toggleActions: "play none none reverse",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.container.querySelectorAll(".item"),
          {
            y: 150,
            stagger: { each: 0.1 },
          },
          0
        )
        .from(
          this.$refs.container.querySelectorAll(".item__content"),
          {
            y: 100,
            stagger: { each: 0.1 },
            ease: "power4.out",
          },
          0
        )
        .from(
          this.$refs.container.querySelectorAll(
            ".item__image img.img-to-animate"
          ),
          {
            scale: 1.5,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>
<style scoped></style>
